<template>
  <div class="back-manage">
    <div class="header">
      智荟网后台管理系统
      <div class="go-forward">
        <img src="~@/assets/icon/home_xjj.png" />
        <router-link to="index">去前台</router-link>
      </div>
    </div>
    <div class="main">
      <div class="aslid">
        <el-menu
          default-active="carouselMain"
          class="el-menu-vertical-demo"
          :router="true"
          @open="handleOpen"
          @close="handleClose"
        >
          <el-submenu index="1">
            <template slot="title">
              <i class="el-icon-setting"></i>
              <span>轮播图管理</span>
            </template>
            <el-menu-item-group>
              <!-- <el-menu-item index="carouselMain">轮播主图</el-menu-item>
              <el-menu-item index="carouselAssistant">轮播副图</el-menu-item>-->
              <el-menu-item index="carouselMain">知识头条</el-menu-item>
              <el-menu-item index="carouselAssistant3">智能问答</el-menu-item>
              <el-menu-item index="carouselAssistant4">资源交易</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-menu-item index="2" route="selected">
            <i class="el-icon-menu"></i>
            <span slot="title">精选文章管理</span>
          </el-menu-item>
          <el-menu-item index="3" route="topics">
            <i class="el-icon-document"></i>
            <span slot="title">主题管理</span>
          </el-menu-item>
          <el-menu-item index="4" route="sensitive">
            <i class="el-icon-document"></i>
            <span slot="title">敏感词过滤</span>
          </el-menu-item>
          <el-menu-item index="themeManage">
            <i class="el-icon-document"></i>
            <span slot="title">维护主题</span>
          </el-menu-item>
          <el-menu-item index="shieldComment">
            <i class="el-icon-document"></i>
            <span slot="title">评论屏蔽</span>
          </el-menu-item>
          <el-menu-item index="Resourceaudit">
            <i class="el-icon-document"></i>
            <span slot="title">资源审核</span>
          </el-menu-item>
          <el-menu-item index="userManage">
            <i class="el-icon-s-custom"></i>
            <span slot="title">用户管理</span>
          </el-menu-item>
          <el-menu-item index="userAccount">
            <i class="el-icon-notebook-2"></i>
            <span slot="title">用户统计</span>
          </el-menu-item>
          <el-menu-item index="sortAccount">
            <i class="el-icon-notebook-2"></i>
            <span slot="title">资源统计</span>
          </el-menu-item>
          <!-- <el-menu-item index="coordinationEdit">
            <i class="el-icon-notebook-2"></i>
            <span slot="title">协同编辑</span>
          </el-menu-item>-->

          <el-submenu index="coordinationEdit">
            <template slot="title">
              <i class="el-icon-edit"></i>
              <span>协同编辑</span>
            </template>
            <el-menu-item-group>
              <!-- <template slot="title">分组一</template> -->
              <el-menu-item right index="coordinationFileList">文件列表</el-menu-item>
              <!-- <el-menu-item index="coordinationFileRecord">编辑记录</el-menu-item> -->
              <el-menu-item index="coordinationUploadFile">上传文件</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="coordinationEdit2">
            <template slot="title">
              <i class="el-icon-tickets"></i>
              <span>一起作业网</span>
            </template>
            <el-menu-item-group>
              <el-menu-item right index="task">发布新闻</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-menu-item index="Knowledge">
            <i class="el-icon-tickets"></i>
            <span slot="title">知识加工</span>
          </el-menu-item>
          <el-menu-item index="ctgl">
            <i class="el-icon-tickets"></i>
            <span slot="title">词条管理</span>
          </el-menu-item>
        </el-menu>
      </div>
      <div class="content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    }
  },
  beforeRouteLeave(to, from, next) {
    if(to.fullPath!='/ctgl'){
      next()
      return
    }
    let token = localStorage.getItem('Authorization')
    let url = 'http://www.izhihui.net/eybak/#/management?token='+token
    // let url = 'http://www.izhihui.net/management?token='+token
    // let url = 'http://106.2.224.58:8037/management?token='+token
    // let url = 'http://101.200.34.92/management?token='+token
    let createA = document.createElement('a')
    createA.href = url
    createA.click()
  }
};
</script>

<style scoped>
.back-manage {
  min-width: 1800px;
  background-color: #f7f7f7;
}
.back-manage .header {
  height: 100px;
  line-height: 100px;
  background-color: rgba(0, 0, 0, 0.87);
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  padding-left: 68px;
}

.aslid {
  display: inline-block;
  width: 200px;
}
.content {
  display: inline-block;
  vertical-align: top;
  padding: 20px;
  /* min-width: 1500px; */
}
.go-forward {
  display: inline-block;
  width: 150px;
  margin-left: 60px;
  right: 30px;
  font-size: 20px;
  font-family: inherit;
  vertical-align: 2px;
  color: #ffffff !important;
}

/* reset style */
.el-submenu >>> .el-menu-item {
  height: 50px;
  line-height: 50px;
  padding: 0 45px;
  min-width: 200px;
  text-align: right;
}
</style>
